import React from 'react'
import PropTypes from 'prop-types'
import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

export default function Slideshow(props) {
  return (
    <div >
      <Fade>
        {props.images?.map((image, index) => (
          <div className="each-fade" key={index}>
            <div className="image-container">
              <img src={image?.file?.url} alt={image?.title} className={props.imgClass} />
            </div>
        </div>
        ))}
      </Fade>
    </div>
  )
}

Slideshow.propTypes = {
  imgClass: PropTypes.string,
  images: PropTypes.array.isRequired
}