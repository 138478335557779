import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'
import * as styles from './card.module.scss'
import classNames from 'classnames'
import { isArray } from 'lodash'
import Slideshow from '../Slideshow'

export default function Card(props) {
  const intl = useIntl()

  const cardContent = () => {
    return (
      <>
        <div className={styles.cardHeader}>
          {!isArray(props.image) ?
            <img
              src={props.image?.file?.url}
              alt={props.image.title || props.title}
              className={styles.cardImage} /> :
            <Slideshow imgClass={styles.cardImage} images={props.image} />}
        </div><div className={styles.cardBody}>
          {props.pageType && <h6 className={styles.cardBodyType}>{props.pageType}</h6>}
          <h3 className={classNames(styles.cardBodyTitle, 'h5')}>{props.title}</h3>
          {props.description && (
            <p className={styles.cardBodyDescription}>{props.description.description}</p>
          )}
          <div className="flex-grow-1" />
          {props.updatedAt && (
            <time className={styles.cardBodyDate}>
              {intl.formatDate(props.updatedAt, { year: 'numeric', month: 'long', day: 'numeric' })}
            </time>
          )}
        </div>
      </>
    )
  }
  return (
    props.noLink ? <div className={classNames(styles.card, { [styles.horizontal]: props.horizontal })}>{cardContent()}</div> : <Link
      className={classNames(styles.card, { [styles.horizontal]: props.horizontal })}
      to={props.to}
    >
      {cardContent()}
    </Link>
  )
}

Card.propTypes = {
  to: PropTypes.string.isRequired,
  noLink: PropTypes.bool,
  horizontal: PropTypes.bool,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  pageType: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

Card.defaultProps = {
  date: null,
  description: null,
  type: null,
}
